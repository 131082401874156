import { template as template_ba5289ed5a9c407799364ec66c717863 } from "@ember/template-compiler";
const FKLabel = template_ba5289ed5a9c407799364ec66c717863(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
