import { template as template_ef5067e7b1bc4d9c935b839dc6535857 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ef5067e7b1bc4d9c935b839dc6535857(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
