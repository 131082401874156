import { template as template_298beca3402b43c497b846cd3e465602 } from "@ember/template-compiler";
import EmojiPickerContent from "discourse/components/emoji-picker/content";
const EmojiPickerDetached = template_298beca3402b43c497b846cd3e465602(`
  <EmojiPickerContent
    @close={{@close}}
    @term={{@data.term}}
    @didSelectEmoji={{@data.didSelectEmoji}}
    @context={{@data.context}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmojiPickerDetached;
