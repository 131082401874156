import { template as template_664ef9e683424c0da7f666e521929206 } from "@ember/template-compiler";
const FKText = template_664ef9e683424c0da7f666e521929206(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
